import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssessment,
  getAssessments,
  getTestCaseForAssessment,
  updateAssessmentData,
} from "../../reducers/AssessmentsReducer";
import TestCase from "../../components/TestCase";
import { useParams } from "react-router-dom";

const AddOrUpdateAssessment = () => {
  const dispatch = useDispatch();

  const { assessments, isAssessmentsProcessing } = useSelector(
    (state) => state.assessments
  );
  const { user } = useSelector((state) => state.auth);
  console.log(user);
  const editorRef = useRef(null);

  const { assessmentId } = useParams();

  const [isTestCasesFetching, setIsTestCasesFetching] = useState(
    !!assessmentId
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [assessment, setAssessment] = useState(
    assessmentId ? assessments?.find(({ id }) => id == assessmentId) : null
  );
  const [isProAssignment, setIsProAssignment] = useState(
    assessment?.isProAssignment ?? false
  );
  const [isActive, setIsActive] = useState(assessment?.isActive ?? true);
  const [isRandomlyAssigned, setIsRandomlyAssigned] = useState(
    assessment?.isRandomlyAssigned ?? false
  );
  const [testCases, setTestCases] = useState([]);

  const onIsProAssignmentClick = (e) => {
    setIsProAssignment(e.currentTarget.checked);
  };

  const onIsActiveClick = (e) => {
    setIsActive(e.currentTarget.checked);
  };

  const onIsRandomlyAssignedClick = (e) => {
    setIsRandomlyAssigned(e.currentTarget.checked);
  };

  const onDeleteTestCase = (index) => {
    setTestCases(testCases.filter((data, _index) => _index !== index));
  };

  const onUpdateTestCase = (index, data) => {
    testCases[index] = data;
    setTestCases([...testCases]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();

    let content = editorRef?.current?.editor?.getContent();
    formData.append("content", content);
    formData.append("isProAssignment", isProAssignment);
    formData.append("isActive", isActive);
    formData.append("isRandomlyAssigned", isRandomlyAssigned);

    setIsProcessing(true);
    dispatch(
      assessment
        ? updateAssessmentData({ assessmentId: assessment.id, formData })
        : addAssessment({ formData })
    )
      .then(({ payload }) => {
        onAssessmentChange({
          ...payload,
          ...assessment,
          testCases: testCases,
        });
      })
      .finally(() => setIsProcessing(false));
  };

  const onAssessmentChange = (assessment) => {
    setAssessment(assessment);
    setTestCases(assessment.testCases ?? testCases);
  };

  const onTestCaseAdd = (testCase) => {
    setTestCases([testCase ?? {}, ...testCases]);
  };

  useEffect(() => {
    if (!isAssessmentsProcessing && assessments === undefined) {
      dispatch(getAssessments()).then(({ payload: { data } }) => {
        return setAssessment(
          data?.find(({ id }) => id == assessmentId) ?? null
        );
      });
    }
  }, []);

  useEffect(() => {
    if (assessment && assessmentId) {
      setIsTestCasesFetching(true);
      dispatch(getTestCaseForAssessment({ assessmentId }))
        .then(({ payload }) => {
          setTestCases(payload);
        })
        .finally(() => setIsTestCasesFetching(false));
    }
  }, [assessmentId, assessment?.id]);

  if (isAssessmentsProcessing)
    return (
      <Box
        my={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Container sx={{ pt: 2 }} maxWidth="lg">
      <Box onSubmit={onSubmit} component={"form"}>
        <Typography sx={{ m: "15px", fontSize: "18px" }}>Assessment</Typography>
        <Box px={2}>
          <FormControlLabel
            disabled={isProcessing}
            control={
              <Checkbox
                checked={isActive}
                onChange={onIsActiveClick}
                name="isActive"
              />
            }
            label="Active"
          />
          <FormControlLabel
            disabled={isProcessing}
            control={
              <Checkbox
                checked={isProAssignment}
                onChange={onIsProAssignmentClick}
                name="isProAssignment"
              />
            }
            label="Pro Assessment"
          />
          <FormControlLabel
            disabled={isProcessing || !isProAssignment}
            control={
              <Checkbox
                checked={isRandomlyAssigned}
                onChange={onIsRandomlyAssignedClick}
                name="isRandomlyAssigned"
              />
            }
            label="Randomised Assignment"
          />
        </Box>
        <Editor
          disabled={isProcessing}
          ref={editorRef}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              // Core editing features
              "anchor",
              "autolink",
              "charmap",
              "codesample",
              "emoticons",
              "image",
              "link",
              "lists",
              "searchreplace",
              "table",
              "visualblocks",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
            exportpdf_converter_options: {
              format: "Letter",
              margin_top: "1in",
              margin_right: "1in",
              margin_bottom: "1in",
              margin_left: "1in",
            },
            exportword_converter_options: { document: { size: "Letter" } },
          }}
          initialValue={assessment?.content ?? "Assessment content!"}
        />
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ m: 2 }}
            size="small"
          >
            Save
          </Button>
        </Box>
      </Box>
      {assessment && isTestCasesFetching ? (
        <Box
          my={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={{ m: "15px", fontSize: "18px" }}>
              Test Cases
            </Typography>
            <Button
              onClick={() => onTestCaseAdd()}
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              sx={{ m: 2 }}
            >
              Add
            </Button>
          </Box>
          <Box my={2}>
            {testCases?.map((testCase, index) => (
              <TestCase
                setAssessment={setAssessment}
                onTestCaseAdd={onTestCaseAdd}
                handleDeleteTestCase={onDeleteTestCase}
                handleUpdateTestCase={onUpdateTestCase}
                assessmentId={assessment?.id}
                testCase={testCase}
                key={index}
                index={index}
              />
            ))}
          </Box>
        </>
      )}
    </Container>
  );
};

export default AddOrUpdateAssessment;
