import { Box, Button } from "@mui/material";
import { routes } from "../../routes";
import RequireAuthorization from "../RequiredAuthorization";
import { useSelector } from "react-redux";

const NavbarLG = ({ onLinkClick }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
      }}
    >
      {routes.map(({ nav, auth, path, displayName, role, ...rest }, index) => {
        return (
          nav &&
          (auth ? (
            (role && user ? role === user.role : true) && (
              <RequireAuthorization role={role} key={index} {...rest}>
                <Button
                  className="nav-btn"
                  sx={{ mr: "8px" }}
                  key={displayName}
                  onClick={onLinkClick}
                  to={path}
                >
                  {displayName}
                </Button>
              </RequireAuthorization>
            )
          ) : (
            <Button
              className="nav-btn"
              sx={{ mr: "8px" }}
              key={index}
              onClick={onLinkClick}
              to={path}
            >
              {displayName}
            </Button>
          ))
        );
      })}
    </Box>
  );
};

export { NavbarLG };
