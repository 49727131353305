import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../reducers/UsersReducer";
import UsersTableRow from "./UsersTableRow";
import { visuallyHidden } from "@mui/utils";

const TableHeadContent = [
  {
    children: <Typography py={2}>Name</Typography>,
    align: "left",
    objectKey: "name",
    sortable: true,
  },
  { children: "Email", align: "left", objectKey: "email", sortable: true },
  { children: "CV", align: "left" },
  { children: "Event", align: "left", objectKey: "event", sortable: true },
  {
    children: "Created Date",
    align: "right",
    sx: { textWrap: "nowrap" },
    objectKey: "createdDate",
    sortable: true,
  },
  {
    children: "Job Role",
    align: "left",
    sx: { textWrap: "nowrap" },
    objectKey: "jobRole",
    sortable: true,
  },
  {
    children: "Mobile Number",
    align: "right",
    sx: { textWrap: "nowrap" },
    objectKey: "mobileNumber",
    sortable: true,
  },
  {
    children: "Account Status",
    align: "left",
    sx: { textWrap: "nowrap" },
  },
  {
    children: "User Status",
    align: "left",
    sx: { textWrap: "nowrap" },
  },
  { children: "Role", align: "left", objectKey: "role", sortable: true },
  { children: "Actions", align: "left" },
];

const UsersTable = ({
  filterValue,
  accountStatus,
  event,
  jobRole,
  onRowClick,
  showEnabledUsersOnly = false,
}) => {
  const dispatch = useDispatch();

  const { users, isUsersProcessing } = useSelector((state) => state.users);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [len, setLen] = useState(Object.values(users ?? {})?.length ?? 0);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");

  const descendingComparator = (a, b, orderBy) => {
    if (!a[orderBy]) return 1;

    if (!b[orderBy]) return -1;

    return a[orderBy].toString().localeCompare(b[orderBy].toString());
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    let arr = Object.values(users ?? {}) ?? [];
    if (
      filterValue ||
      (accountStatus !== undefined && accountStatus !== null) ||
      (event !== undefined && event !== null) ||
      (jobRole !== undefined && jobRole !== null) ||
      order ||
      orderBy
    ) {
      arr = arr
        ?.filter(
          (u) =>
            ((showEnabledUsersOnly && u.isActive) || !showEnabledUsersOnly) &&
            (filterValue
              ? u.name?.includes(filterValue) || u.email?.includes(filterValue)
              : true) &&
            (accountStatus !== undefined && accountStatus !== null
              ? u.accountStatus === accountStatus
              : true) &&
            (event !== null
              ? event === ""
                ? u.event === undefined || u.event === null
                : u.event === event
              : true) &&
            (jobRole !== null
              ? jobRole === ""
                ? !u.jobRole
                : u.jobRole === jobRole
              : true)
        )
        ?.sort(getComparator(order, orderBy));
    }
    setLen(arr.length);
    return (
      arr?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? []
    );
  }, [
    showEnabledUsersOnly,
    users,
    filterValue,
    accountStatus,
    event,
    jobRole,
    page,
    rowsPerPage,
    order,
    orderBy,
  ]);

  useEffect(() => {
    if (!isUsersProcessing && users === undefined) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterValue, accountStatus, event, jobRole]);

  if (users === undefined || isUsersProcessing)
    return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Box>
    );

  return Object.keys(users ?? {})?.[0] ? (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ py: 1 }} className={"header"}>
              <TableCell />
              {TableHeadContent.map(
                ({ children, objectKey, sortable, ...rest }, index) => {
                  return sortable ? (
                    <TableCell key={index} {...rest}>
                      <TableSortLabel
                        active={orderBy === objectKey}
                        direction={orderBy === objectKey ? order : "asc"}
                        onClick={(e) => handleRequestSort(objectKey)}
                      >
                        {children}
                        {orderBy === objectKey ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell {...rest}>{children}</TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map(({ id, ...values }) => (
              <UsersTableRow
                key={id}
                id={id}
                onClick={onRowClick}
                {...values}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="table-pagination"
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={len}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  ) : (
    <Typography textAlign={"center"} variant="h6" color="white">
      No users found
    </Typography>
  );
};

export default UsersTable;
