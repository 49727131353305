import { Box, Button, Container } from "@mui/material";
import AssessmentsTable from "../components/AssessmentsTable";
import { router } from "../routes";

const Assessments = () => {
  return (
    <Container maxWidth="lg">
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            router.navigate("/assessments/add");
          }}
          sx={{ my: 2, mr: 2 }}
          size="small"
          variant="contained"
          color="secondary"
        >
          Add
        </Button>
      </Box>
      <AssessmentsTable />
    </Container>
  );
};

export default Assessments;
