import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssessments } from "../reducers/AssessmentsReducer";
import AssessmentChip from "../components/AssessmentChip";

const UserAssessments = () => {
  const dispatch = useDispatch();

  const { assessments, isAssessmentsProcessing } = useSelector(
    (state) => state.assessments
  );

  useEffect(() => {
    if (!isAssessmentsProcessing && assessments === undefined) {
      dispatch(getAssessments());
    }
  }, []);

  if (isAssessmentsProcessing && assessments === undefined) {
    return (
      <Box width={"100%"} display={"flex"} justifyContent={"center"} my={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (assessments === null) {
    return (
      <Box width={"100%"} display={"flex"} justifyContent={"center"} my={3}>
        <Typography variant="body2">No assessments found</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box my={3}>
        <Typography variant="h1" align="center">
          Assessments
        </Typography>
      </Box>
      <Divider sx={{ color: "var(--faded)" }}>Basic Assessments</Divider>
      <Box mt={1} mb={3} display={"flex"} flexWrap={"wrap"}>
        {assessments?.map(
          ({ id, isProAssignment, assessmentStatus }, index) =>
            !isProAssignment && (
              <AssessmentChip
                status={assessmentStatus}
                key={index}
                id={id}
                number={index + 1}
              />
            )
        )}
      </Box>
      <Divider sx={{ color: "var(--faded)" }}>Pro Assessments</Divider>
      <Box mt={1} display={"flex"} flexWrap={"wrap"}>
        {assessments?.map(
          ({ id, isProAssignment, assessmentStatus }, index) =>
            isProAssignment && (
              <AssessmentChip
                status={assessmentStatus}
                key={index}
                id={id}
                number={index + 1}
              />
            )
        )}
      </Box>
    </Container>
  );
};

export default UserAssessments;
