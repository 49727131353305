import { Paper, Typography } from "@mui/material";
import "./AssessmentChip.css";
import { router } from "../routes";

const AssessmentChip = ({ number, id, status }) => {
  const onClickRoute = () => {
    router.navigate(`/user/assessment/${id}`);
  };

  if (status === 0)
    return (
      <Paper
        className="assessment-chip"
        elevation={3}
        onClick={onClickRoute}
        sx={{
          maxWidth: "300px",
          p: "6px 12px",
          borderRadius: "15px",
          m: "10px",
        }}
      >
        <Typography>Assessment {number} </Typography>
      </Paper>
    );
  if (status === 1)
    return (
      <Paper
        className="assessment-chip"
        elevation={3}
        onClick={onClickRoute}
        sx={{
          maxWidth: "300px",
          p: "6px 12px",
          borderRadius: "15px",
          m: "10px",
        }}
      >
        <Typography>Assessment {number} </Typography>
      </Paper>
    );
  if (status === 2)
    return (
      <Paper
        className="assessment-chip"
        elevation={3}
        onClick={onClickRoute}
        sx={{
          maxWidth: "300px",
          p: "6px 12px",
          borderRadius: "15px",
          m: "10px",
        }}
      >
        <Typography>Assessment {number} </Typography>
      </Paper>
    );
};

export default AssessmentChip;
