import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Users from "./pages/Users";
import SignUp from "./pages/SignUp";
import Assessments from "./pages/Assessments";
import AddOrUpdateAssessment from "./pages/Assessmet/AddOrUpdate";
import Scoreboard from "./pages/Scoreboard";
import UserAssessments from "./pages/UserAssessments";
import UserAssessment from "./pages/Assessmet/UserAssessment";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
    displayName: "Home",
    nav: true,
  },
  {
    path: "/signin",
    element: <SignIn />,
    displayName: "Sign In",
    nav: true,
    auth: true,
    requireAuthorization: false,
  },
  {
    path: "/signup",
    element: <SignUp />,
    displayName: "Sign Up",
    nav: true,
    auth: true,
    requireAuthorization: false,
  },
  {
    path: "/users",
    element: <Users />,
    displayName: "Users",
    nav: true,
    auth: true,
    role: "admin",
  },
  {
    path: "/assessments",
    element: <Assessments />,
    displayName: "Assessments",
    nav: true,
    auth: true,
    role: "admin",
  },
  {
    path: "/assessments/update/:assessmentId",
    element: <AddOrUpdateAssessment />,
  },
  {
    path: "/assessments/add",
    element: <AddOrUpdateAssessment />,
  },
  {
    path: "/scoreboard",
    element: <Scoreboard />,
    displayName: "Scoreboard",
    nav: true,
    auth: true,
    role: "admin",
  },
  {
    path: "/user/assessments",
    element: <UserAssessments />,
    displayName: "Assessments",
    nav: true,
    auth: true,
    role: "user",
  },
  {
    path: "/user/assessment/:assessmentId",
    element: <UserAssessment />,
  },
];

const router = createBrowserRouter(routes);

export { router, routes };
