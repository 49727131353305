import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from "../reducers/RolesReducer";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";

const RoleSelector = ({
  defaultValue,
  value,
  onChange,
  containerProps,
  ...props
}) => {
  const dispatch = useDispatch();
  const { roles, isRolesProcessing } = useSelector((state) => state.roles);

  useEffect(() => {
    if (!isRolesProcessing && roles === undefined) {
      dispatch(getAllRoles());
    }
  }, []);

  if (roles === undefined || isRolesProcessing)
    return <Skeleton width={"100%"} />;

  return (
    <FormControl size="small" {...containerProps}>
      <InputLabel id="role-select-label">Select Role</InputLabel>
      <Select
        labelId="role-select-label"
        id="role-select"
        defaultValue={defaultValue}
        value={value}
        label="Select Role"
        name="role"
        size="small"
        onChange={onChange}
        {...props}
      >
        {roles?.map(({ name, displayName }) => (
          <MenuItem key={name} value={name}>
            {displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleSelector;
