import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllScoredSubmissions } from "../../reducers/SubmissionsReducer";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import ScoreBoadTableRow from "./ScoreBoadTableRow";
import { getAllUsers } from "../../reducers/UsersReducer";

const TableHeadContent = [
  { children: "Name", align: "left" },
  {
    children: "Email",
    align: "left",
    sx: { textWrap: "nowrap" },
    objectKey: "content",
  },
  {
    children: "Last Submitted Date",
    align: "center",
    sx: { textWrap: "nowrap" },
  },
  {
    children: "Score",
    align: "center",
    sx: { textWrap: "nowrap" },
  },
  {
    children: "Completed Pro Assessments Count",
    align: "center",
    sx: { textWrap: "nowrap" },
  },
];

const ScoreBoardTable = ({
  nameOrEmail,
  showEnabledUsersOnly,
  minimunCompletedProAssessmentsCount,
}) => {
  const dispatch = useDispatch();

  const { scoredSubmissions, isSubmissionsProcessing } = useSelector(
    (state) => state.submissions
  );
  const { users, isUsersProcessing } = useSelector((state) => state.users);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = useMemo(() => {
    let filtered = (scoredSubmissions ?? []).filter(
      (submission) =>
        (!showEnabledUsersOnly || users[submission.userId].isActive) &&
        (!nameOrEmail ||
          users[submission.userId].name.includes(nameOrEmail) ||
          users[submission.userId].email.includes(nameOrEmail)) &&
        submission.completedProAssignmentsCount >=
          (minimunCompletedProAssessmentsCount ?? 0)
    );

    return (
      filtered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ??
      []
    );
  }, [
    scoredSubmissions,
    page,
    rowsPerPage,
    showEnabledUsersOnly,
    users,
    nameOrEmail,
    minimunCompletedProAssessmentsCount,
  ]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!isUsersProcessing && users === undefined) {
      dispatch(getAllUsers()).then(() => {
        if (scoredSubmissions === undefined) {
          dispatch(getAllScoredSubmissions());
        }
      });
    }
  }, []);

  if (scoredSubmissions === undefined || isSubmissionsProcessing)
    return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Box>
    );

  return scoredSubmissions?.[0] ? (
    <>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ py: 1 }} className={"header"}>
              {TableHeadContent.map(
                ({ children, objectKey, sortable, ...rest }, index) => {
                  return (
                    <TableCell key={index} {...rest}>
                      {children}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map(({ id, userId, ...values }, index) => (
              <ScoreBoadTableRow
                key={index}
                user={users[userId]}
                id={id}
                {...values}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        size="small"
        className="table-pagination"
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={scoredSubmissions?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  ) : (
    <Typography textAlign={"center"} variant="h6" color="white">
      No users found
    </Typography>
  );
};

export default ScoreBoardTable;
