import axios from "axios";
import {
  ASSESSMENTS,
  ATTACHMENTS,
  AUTH,
  OAUTH,
  SUBMISSIONS,
  USERS,
} from "./api_routes";
import { addNotification } from "../reducers/NotificationsReducer";

const headers = { "Content-Type": "application/json" };

const requestWrapper = async (dispatch, request) => {
  try {
    return await request();
  } catch (error) {
    const message =
      typeof error.response?.data === "string"
        ? error.response?.data
        : error?.message;
    dispatch(
      addNotification({
        message: message ?? "Something went wrong",
        type: "error",
      })
    );
    throw error;
  }
};

const getPublicKey = async (dispatch) => {
  const api = AUTH.GET_PUBLIC_KEY;

  return requestWrapper(dispatch, async () => {
    let res = await axios({ url: api.url, method: api.method });
    return { status: res.status, data: res.data };
  });
};

const postLogin = async (dispatch, formData) => {
  const { url, method } = AUTH.LOGIN;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      data: formData,
      headers,
    });
  });
};

const postSignUp = async (dispatch, formData) => {
  const { url, method } = AUTH.SIGN_UP;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      data: formData,
      headers,
    });
  });
};

const oauthAuthenticate = async (dispatch, formData) => {
  const { url, method } = OAUTH.AUTHENTICATE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      data: formData,
      headers,
    });
  });
};

const createUser = async (dispatch, formData) => {
  const { url, method } = USERS.CREATE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      data: formData,
      headers,
    });
  });
};

const updateUser = async (dispatch, formData) => {
  const { url, method } = USERS.UPDATE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      data: formData,
      headers,
    });
  });
};

const getUsers = async (dispatch) => {
  const { url, method } = USERS.GET;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
    });
  });
};

const getRoles = async (dispatch) => {
  const { url, method } = USERS.ROLES;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
    });
  });
};

const assignRole = async (dispatch, email, role) => {
  const { url, method } = USERS.ASSIGN_ROLE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { email, role },
    });
  });
};

const updateActivateStatus = async (dispatch, id, isActive) => {
  const { url, method } = USERS.UPDATE_ACTIVATE_STATUS;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { id, isActive },
    });
  });
};

const inviteUser = async (dispatch, userId) => {
  const { url, method } = USERS.INVITE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { userId },
    });
  });
};

const uploadAttachment = async (dispatch, data, onUploadProgress) => {
  const { url, method } = ATTACHMENTS.UPLOAD;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
      onUploadProgress,
    });
  });
};

const getAllAssessments = async (dispatch, email) => {
  const { url, method } = ASSESSMENTS.GET;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { email },
    });
  });
};

const deleteAssessment = async (dispatch, assessmentId) => {
  const { url, method } = ASSESSMENTS.DELETE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { assessmentId },
    });
  });
};

const createAssessment = async (dispatch, data) => {
  const { url, method } = ASSESSMENTS.CREATE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      data,
    });
  });
};

const updateAssessment = async (dispatch, assessmentId, data) => {
  const { url, method } = ASSESSMENTS.UPDATE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { assessmentId },
      data,
    });
  });
};
const getTestCases = async (dispatch, assessmentId) => {
  const { url, method } = ASSESSMENTS.TEST_CASE_GET;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { assessmentId },
    });
  });
};
const postTestCase = async (dispatch, assessmentId, data) => {
  console.log(assessmentId);
  const { url, method } = ASSESSMENTS.TEST_CASE_ADD;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { assessmentId },
      data,
    });
  });
};

const deleteTestCase = async (dispatch, testCaseId) => {
  const { url, method } = ASSESSMENTS.TEST_CASE_DELETE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { testCaseId },
    });
  });
};

const updateTestCase = async (dispatch, testCaseId, data) => {
  const { url, method } = ASSESSMENTS.TEST_CASE_UPDATE;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
      params: { testCaseId },
      data,
    });
  });
};

const getScoredSubmissions = async (dispatch) => {
  const { url, method } = SUBMISSIONS.GET_SCORES;
  return requestWrapper(dispatch, async () => {
    return await axios({
      url,
      method,
      headers,
    });
  });
};

export {
  getPublicKey,
  postLogin,
  postSignUp,
  oauthAuthenticate,
  createUser,
  updateUser,
  updateActivateStatus,
  getRoles,
  getUsers,
  inviteUser,
  uploadAttachment,
  assignRole,
  getAllAssessments,
  updateAssessment,
  deleteAssessment,
  createAssessment,
  postTestCase,
  deleteTestCase,
  updateTestCase,
  getTestCases,
  getScoredSubmissions,
};
