import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getScoredSubmissions } from "../api/requests";

const getAllScoredSubmissions = createAsyncThunk(
  "submissions/getScoredSubmissions",
  async (_, { dispatch }) => {
    const { data } = await getScoredSubmissions(dispatch);
    return data;
  }
);

const initialState = {
  isSubmissionsProcessing: true,
  scoredSubmissions: undefined,
};

const slice = createSlice({
  name: "SubmissionsReducer",
  initialState: initialState,
  reducers: {
    setProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllScoredSubmissions.pending, (state) => {
        state.isSubmissionsProcessing = true;
        state.scoredSubmissions = null;
      })
      .addCase(getAllScoredSubmissions.fulfilled, (state, { payload }) => {
        state.isSubmissionsProcessing = false;

        payload.forEach((user) => {
          let successfulSubmissions = {};
          let completedProAssignments = new Set();

          user.successfulSubmissions.forEach(
            ({ assessmentId, submissionId, isProAssessment }) => {
              if (successfulSubmissions[assessmentId])
                successfulSubmissions[assessmentId].push(submissionId);
              else successfulSubmissions[assessmentId] = [submissionId];

              if (isProAssessment && !completedProAssignments.has(assessmentId))
                completedProAssignments.add(assessmentId);
            }
          );

          user.successfulSubmissions = successfulSubmissions;
          user.completedProAssignmentsCount = completedProAssignments.size;
        });

        state.scoredSubmissions = payload;
      })
      .addCase(getAllScoredSubmissions.rejected, (state) => {
        state.isSubmissionsProcessing = false;
        state.scoredSubmissions = null;
      }),
});

export const { setProcessing } = slice.actions;

export default slice.reducer;

export { getAllScoredSubmissions };
