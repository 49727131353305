import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

const ScoreBoardFilter = ({
  nameOrEmail,
  setNameOrEmail,
  minimunCompletedProAssessmentsCount,
  setMinimunCompletedProAssessmentsCount,
  showEnabledUsersOnly,
  setshowEnabledUsersOnly,
}) => {
  const onNameOrEmailChanged = (e) => {
    setNameOrEmail(e.currentTarget.value);
  };

  const onMinimunCompletedProAssessmentsCount = (e) => {
    setMinimunCompletedProAssessmentsCount(e.currentTarget.value);
  };

  const onClear = () => {
    setNameOrEmail(null);
    setMinimunCompletedProAssessmentsCount(-1);
  };

  const onshowEnabledUsersOnlyChanged = (val, checked) => {
    setshowEnabledUsersOnly(checked);
  };

  return (
    <Paper elevation={1} sx={{ my: 3, p: 1 }}>
      <Box display={"flex"} alignItems={"center"}>
        <Typography ml={3} variant="body1">
          Filter Users
        </Typography>
        <FormControl sx={{ ml: "auto" }} component="fieldset">
          <FormControlLabel
            value={showEnabledUsersOnly ? "Enabled" : "Disabled"}
            control={
              <Switch
                onChange={onshowEnabledUsersOnlyChanged}
                color="primary"
              />
            }
            label={"Show 'Enabled' users only"}
            labelPlacement="end"
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box display={"flex"} flexWrap={"wrap"} flexGrow={1}>
          <TextField
            value={nameOrEmail ?? ""}
            onChange={onNameOrEmailChanged}
            sx={{ display: "flex", flexGrow: 1, mr: "10px", mt: "10px" }}
            size="small"
            label="Name or Email"
          />
          <FormControl
            sx={{ display: "flex", flexGrow: 1, mr: "10px", mt: "10px" }}
          >
            <InputLabel>Minimum Completed Pro Assessment Count</InputLabel>
            <OutlinedInput
              value={minimunCompletedProAssessmentsCount ?? ""}
              onChange={onMinimunCompletedProAssessmentsCount}
              size="small"
              label="Minimum Completed Pro Assessment Count"
              slotProps={{ input: { inputMode: "numeric", min: 0 } }}
            />
          </FormControl>
        </Box>
        <Box m={"10px"} ml={"auto"}>
          <Button onClick={onClear} variant="contained" size="small">
            Clear
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default ScoreBoardFilter;
