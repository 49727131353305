import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssessments,
  removeAssessment,
  updateAssessmentData,
} from "../../reducers/AssessmentsReducer";
import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import parse from "html-react-parser";
import { Delete } from "@mui/icons-material";

const AssessmentsTableRow = ({
  id,
  content,
  isRandomlyAssigned,
  isProAssignment,
  isActive,
  onClick,
}) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [assessment, setAssessment] = useState({
    content,
    isRandomlyAssigned,
    isProAssignment,
    isActive,
  });

  const handleOnClick = () => {
    onClick({
      id,
      content,
      isRandomlyAssigned,
      isProAssignment,
      isActive,
    });
  };

  const stopPropogation = (e) => {
    e.stopPropagation();
  };

  const onChange = (e) => {
    let name = e.currentTarget.getAttribute("name");
    let value = e.currentTarget.checked;

    let newAssessment = { ...assessment, [name]: value };

    let formData = new FormData();
    Object.entries(newAssessment).forEach(([key, val]) =>
      formData.append(key, val)
    );

    setIsProcessing(true);
    dispatch(updateAssessmentData({ assessmentId: id, formData }))
      .then(() => {
        setAssessment(newAssessment);
      })
      .finally(() => setIsProcessing(false));
  };

  const onDelete = (e) => {
    stopPropogation(e);
    setIsProcessing(true);
    dispatch(removeAssessment({ assessmentId: id })).finally(() =>
      setIsProcessing(false)
    );
  };

  return (
    <TableRow
      className={`${
        isProcessing ? "is-processing" : onClick ? "on-click-active " : ""
      } styled`}
      onClick={!isProcessing && onClick ? handleOnClick : undefined}
    >
      <TableCell>{id}</TableCell>
      <TableCell>{parse(content)}</TableCell>
      <TableCell align="center">
        <Checkbox
          onChange={onChange}
          name="isActive"
          onClick={stopPropogation}
          defaultChecked={isActive}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          onChange={onChange}
          name="isProAssignment"
          onClick={stopPropogation}
          defaultChecked={isProAssignment}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          onChange={onChange}
          disabled={!isProAssignment}
          name="isRandomlyAssigned"
          onClick={stopPropogation}
          defaultChecked={isProAssignment && isRandomlyAssigned}
        />
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default AssessmentsTableRow;
