import { Container } from "@mui/material";
import ScoreBoardTable from "../components/ScoreBoadTable";
import { useState } from "react";
import ScoreBoardFilter from "../components/ScoreBoardFilter";

const Scoreboard = () => {
  const [nameOrEmail, setNameOrEmail] = useState(null);
  const [
    minimunCompletedProAssessmentsCount,
    setMinimunCompletedProAssessmentsCount,
  ] = useState(0);
  const [showEnabledUsersOnly, setshowEnabledUsersOnly] = useState(false);

  return (
    <Container sx={{ py: 3 }} maxWidth="lg">
      <ScoreBoardFilter
        minimunCompletedProAssessmentsCount={
          minimunCompletedProAssessmentsCount
        }
        nameOrEmail={nameOrEmail}
        setMinimunCompletedProAssessmentsCount={
          setMinimunCompletedProAssessmentsCount
        }
        setNameOrEmail={setNameOrEmail}
        setshowEnabledUsersOnly={setshowEnabledUsersOnly}
        showEnabledUsersOnly={showEnabledUsersOnly}
      />
      <ScoreBoardTable
        nameOrEmail={nameOrEmail}
        showEnabledUsersOnly={showEnabledUsersOnly}
        minimunCompletedProAssessmentsCount={
          minimunCompletedProAssessmentsCount
        }
      />
    </Container>
  );
};

export default Scoreboard;
