import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createAssessment,
  deleteAssessment,
  deleteTestCase,
  getAllAssessments,
  getTestCases,
  postTestCase,
  updateAssessment,
  updateTestCase,
} from "../api/requests";

const getAssessments = createAsyncThunk(
  "assessments/get",
  async ({ email } = {}, { dispatch }) => {
    const { data } = await getAllAssessments(dispatch, email);
    return { data };
  }
);

const removeAssessment = createAsyncThunk(
  "assessments/delete",
  async ({ assessmentId }, { dispatch }) => {
    await deleteAssessment(dispatch, assessmentId);
    return { assessmentId };
  }
);
const updateAssessmentData = createAsyncThunk(
  "assessments/update",
  async ({ assessmentId, formData }, { dispatch }) => {
    const { data } = await updateAssessment(dispatch, assessmentId, formData);
    return data;
  }
);
const addAssessment = createAsyncThunk(
  "assessments/create",
  async ({ formData }, { dispatch }) => {
    const { data } = await createAssessment(dispatch, formData);
    return data;
  }
);
const getTestCaseForAssessment = createAsyncThunk(
  "assessments/testcase/get",
  async ({ assessmentId }, { dispatch }) => {
    const { data } = await getTestCases(dispatch, assessmentId);
    return data;
  }
);
const addTestCaseToAssessment = createAsyncThunk(
  "assessments/testcase/create",
  async ({ assessmentId, formData }, { dispatch }) => {
    const { data } = await postTestCase(dispatch, assessmentId, formData);
    return data;
  }
);
const updateTestCaseInAssessment = createAsyncThunk(
  "assessments/testcase/create",
  async ({ testCaseId, formData }, { dispatch }) => {
    const { data } = await updateTestCase(dispatch, testCaseId, formData);
    return data;
  }
);
const deleteTestCaseAssessment = createAsyncThunk(
  "assessments/testcase/delete",
  async ({ testCaseId }, { dispatch }) => {
    const { data } = await deleteTestCase(dispatch, testCaseId);
    return data;
  }
);

const initialState = {
  isAssessmentsProcessing: false,
  assessments: undefined,
};

const slice = createSlice({
  name: "AssessmentsReducer",
  initialState: initialState,
  reducers: {
    setProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAssessments.pending, (state, { payload }) => {
        state.isAssessmentsProcessing = true;
      })
      .addCase(getAssessments.fulfilled, (state, { payload: { data } }) => {
        state.assessments = (data ?? [])
          .sort((a1, a2) => a1.id - a2.id)
          .sort((a1, a2) => a1.isProAssignment - a2.isProAssignment);
        state.isAssessmentsProcessing = false;
      })
      .addCase(getAssessments.rejected, (state, { payload, error }) => {
        state.assessments = null;
        state.isUsersProcessing = false;
      })
      .addCase(updateAssessmentData.fulfilled, (state, { payload }) => {
        let arr = state.assessments ?? [payload];

        state.assessments = arr.map((obj) =>
          obj.id === payload.id ? payload : obj
        );
      })
      .addCase(
        removeAssessment.fulfilled,
        (state, { payload: { assessmentId } }) => {
          state.assessments =
            state.assessments?.filter((obj) => obj.id !== assessmentId) ?? [];
        }
      )
      .addCase(addAssessment.fulfilled, (state, { payload, error }) => {
        if (state.assessments == null) state.assessments = [];

        if (state.assessments) {
          state.assessments = [payload, ...state.assessments];
        }
      }),
});

export const { setProcessing, reset } = slice.actions;

export default slice.reducer;

export {
  getAssessments,
  removeAssessment,
  updateAssessmentData,
  addAssessment,
  addTestCaseToAssessment,
  updateTestCaseInAssessment,
  deleteTestCaseAssessment,
  getTestCaseForAssessment,
};
