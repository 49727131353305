import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssessments } from "../../reducers/AssessmentsReducer";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import AssessmentsTableRow from "./AssessmentsTableRow";
import { router } from "../../routes";

const TableHeadContent = [
  { children: "Id", align: "left" },
  {
    children: "Question Content",
    align: "left",
    sx: { maxWitdh: "400px", textWrap: "nowrap" },
    objectKey: "content",
  },
  {
    children: "Is Active",
    align: "center",
    sx: { textWrap: "nowrap" },
    objectKey: "isActive",
  },
  {
    children: "Is Pro Assignment",
    align: "center",
    sx: { textWrap: "nowrap" },
    objectKey: "isProAssignment",
  },
  {
    children: "Is Randomly Assignable",
    align: "center",
    sx: { textWrap: "nowrap" },
    objectKey: "isRandomlyAssigned",
  },
  {
    children: "Actions",
    align: "center",
  },
];

const AssessmentsTable = () => {
  const dispatch = useDispatch();

  const { assessments, isAssessmentsProcessing } = useSelector(
    (state) => state.assessments
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = useMemo(() => {
    return (
      assessments?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ) ?? []
    );
  }, [assessments, page, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigateToUpdate = ({ id }) => {
    router.navigate(`/assessments/update/${id}`);
  };

  useEffect(() => {
    if (!isAssessmentsProcessing && assessments === undefined) {
      dispatch(getAssessments());
    }
  }, []);

  if (assessments === undefined || isAssessmentsProcessing)
    return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Box>
    );

  return assessments?.[0] ? (
    <>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ py: 1 }} className={"header"}>
              {TableHeadContent.map(
                ({ children, objectKey, sortable, ...rest }, index) => {
                  return (
                    <TableCell key={index} {...rest}>
                      {children}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map(({ id, ...values }) => (
              <AssessmentsTableRow
                onClick={navigateToUpdate}
                key={id}
                id={id}
                {...values}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        size="small"
        className="table-pagination"
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={assessments?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  ) : (
    <Typography textAlign={"center"} variant="h6" color="white">
      No users found
    </Typography>
  );
};

export default AssessmentsTable;
