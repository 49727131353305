import { Box, Container } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAssessments } from "../../reducers/AssessmentsReducer";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import CodeEditor from "../../components/CodeEditor";
import { getTestCases } from "../../api/requests";
import "./UserAssessment.css";

const UserAssessment = () => {
  const dispatch = useDispatch();

  const { assessmentId } = useParams();

  const { assessments, isAssessmentsProcessing } = useSelector(
    (state) => state.assessments
  );
  const { user } = useSelector((state) => state.auth);

  const [testCasesProcessing, setTestCasesProcessing] = useState(false);
  const [testCases, setTestCases] = useState(null);

  const currentAssessment = useMemo(
    () => assessments?.find(({ id }) => assessmentId == id),
    [assessments, assessmentId]
  );

  useEffect(() => {
    if (!isAssessmentsProcessing && assessments === undefined) {
      dispatch(getAssessments());
    }

    if (assessmentId) {
      setTestCasesProcessing(true);
      getTestCases(dispatch, assessmentId)
        .then(({ data }) => {
          setTestCases(data);
        })
        .finally(() => setTestCasesProcessing(false));
    }
  }, []);

  if (isAssessmentsProcessing && assessments === undefined) {
    return (
      <Box width={"100%"} display={"flex"} justifyContent={"center"} my={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (!currentAssessment)
    return (
      <Container maxWidth={"lg"}>
        <Typography>Assessment not found</Typography>
      </Container>
    );

  return (
    <Container sx={{ py: 2 }} className="assessment-solver" maxWidth={"100vw"}>
      <Box className="assessment">
        <Box>{parse(currentAssessment?.content ?? "")}</Box>
        <Divider />
        <Box>
          <Typography variant="h6" my={2}>
            Examples
          </Typography>
          {testCasesProcessing ? (
            <CircularProgress />
          ) : (
            <Box mb={3}>
              {testCases?.map(({ content, output }, index) => (
                <Card sx={{ m: 2, p: 2 }} key={index}>
                  <Typography fontSize={"16px"} fontWeight={700}>
                    Example {index + 1}
                  </Typography>
                  <CardContent display={"flex"}>
                    <Box p={1} pb={0}>
                      <Box display={"flex"}>
                        <Typography fontWeight={700}>Input:</Typography>
                        <Typography ml={1} whiteSpace={"pre"}>
                          {content}
                        </Typography>
                      </Box>
                      <Box mt={"4px"} display={"flex"}>
                        <Typography fontWeight={700}>Output:</Typography>
                        <Typography ml={1} whiteSpace={"pre"}>
                          {output}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box className="code-editor">
        <CodeEditor
          defaultValue={localStorage.getItem(
            `${user.nameid}-assessment-${assessmentId}`
          )}
          onChange={(e) =>
            localStorage.setItem(`${user.nameid}-assessment-${assessmentId}`, e)
          }
          height={"90%"}
        />
        <Box display={"flex"} alignItems={"flex-end"}>
          <Button
            sx={{ ml: "auto !important", m: 2 }}
            color="secondary"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserAssessment;
