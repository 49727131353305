import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ACCOUNT_STATUS } from "../util/constants";
import { useSelector } from "react-redux";

const UserFilter = ({
  filterValue,
  accountStatus,
  setFilterValue,
  setAccountStatus,
  event,
  jobRole,
  setEvent,
  setJobRole,
  showEnabledUsersOnly,
  setshowEnabledUsersOnly,
}) => {
  const { events, jobRoles } = useSelector((state) => state.users);

  const onFilterValueChanged = (e) => {
    setFilterValue(e.currentTarget.value);
  };

  const getValue = (val) => {
    switch (val) {
      case "all":
        return null;
      case "empty":
        return "";
      default:
        return val;
    }
  };

  const constructValue = (val) => {
    switch (val) {
      case undefined:
      case null:
        return "all";
      case "":
        return "empty";
      default:
        return val;
    }
  };

  const onAccountStatusChanged = (e, { props: { value } }) => {
    setAccountStatus(getValue(value));
  };

  const onEventChanged = (e, { props: { value } }) => {
    setEvent(getValue(value));
  };

  const onJobRoleChanged = (e, { props: { value } }) => {
    setJobRole(getValue(value));
  };

  const onClear = () => {
    setFilterValue(null);
    setAccountStatus(null);
    setEvent(null);
    setJobRole(null);
  };

  const onshowEnabledUsersOnlyChanged = (val, checked) => {
    setshowEnabledUsersOnly(checked);
  };

  return (
    <Paper elevation={1} sx={{ my: 3, p: 1 }}>
      <Box display={"flex"} alignItems={"center"}>
        <Typography ml={3} variant="body1">
          Filter Users
        </Typography>
        <FormControl sx={{ ml: "auto" }} component="fieldset">
          <FormControlLabel
            value={showEnabledUsersOnly ? "Enabled" : "Disabled"}
            control={
              <Switch
                onChange={onshowEnabledUsersOnlyChanged}
                color="primary"
              />
            }
            label={"Show 'Enabled' users only"}
            labelPlacement="left"
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box display={"flex"} flexWrap={"wrap"} flexGrow={1}>
          <TextField
            value={filterValue ?? ""}
            onChange={onFilterValueChanged}
            sx={{ display: "flex", flexGrow: 1, mr: "10px", mt: "10px" }}
            size="small"
            label="Name or Email"
          />
          {events && (
            <FormControl
              sx={{ flexGrow: 1, mr: "10px", mt: "10px" }}
              size="small"
            >
              <InputLabel id="event-selecter-label">Event</InputLabel>
              <Select
                sx={{ minWidth: "150px" }}
                id="event-selecter"
                size="small"
                label="Event"
                defaultValue={"all"}
                onChange={onEventChanged}
                value={constructValue(event)}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"empty"}>Empty</MenuItem>
                {events?.map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {jobRoles && (
            <FormControl
              sx={{ flexGrow: 1, mr: "10px", mt: "10px" }}
              size="small"
            >
              <InputLabel id="job-role-selecter-label">Job Role</InputLabel>
              <Select
                sx={{ minWidth: "150px" }}
                id="job-role-selecter"
                size="small"
                label="Job Role"
                defaultValue={"all"}
                onChange={onJobRoleChanged}
                value={constructValue(jobRole)}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"empty"}>Empty</MenuItem>
                {jobRoles?.map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl
            sx={{ flexGrow: 1, mr: "10px", mt: "10px" }}
            size="small"
          >
            <InputLabel id="account-status-selecter-label">
              Account Status
            </InputLabel>
            <Select
              sx={{ minWidth: "150px" }}
              id="account-status-selecter"
              size="small"
              label="Account Status"
              defaultValue={"all"}
              onChange={onAccountStatusChanged}
              value={accountStatus ?? "all"}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {Object.entries(ACCOUNT_STATUS).map(([key, val]) => (
                <MenuItem key={key} value={val}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box m={"10px"} ml={"auto"}>
          <Button onClick={onClear} variant="contained" size="small">
            Clear
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default UserFilter;
