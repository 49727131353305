import { configureStore } from "@reduxjs/toolkit";
import ProcessReducer from "./reducers/ProcessReducer";
import NotificationsReducer from "./reducers/NotificationsReducer";
import AuthReducer from "./reducers/AuthReducer";
import UsersReducer from "./reducers/UsersReducer";
import RolesReducer from "./reducers/RolesReducer";
import AssessmentsReducer from "./reducers/AssessmentsReducer";
import SubmissionsReducer from "./reducers/SubmissionsReducer";

const store = configureStore({
  reducer: {
    assessments: AssessmentsReducer,
    auth: AuthReducer,
    process: ProcessReducer,
    notifications: NotificationsReducer,
    roles: RolesReducer,
    submissions: SubmissionsReducer,
    users: UsersReducer,
  },
});

export default store;
