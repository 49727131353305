import { Editor } from "@monaco-editor/react";

const CodeEditor = ({ height, ...rest }) => {
  return (
    <Editor
      height={height}
      language="javascript"
      theme="vs-dark"
      options={{
        roundedSelection: true,
        inlineSuggest: true,
        fontSize: "16px",
        formatOnType: true,
        autoClosingBrackets: true,
        minimap: { scale: 10 },
        automaticLayout: true,
      }}
      {...rest}
    />
  );
};

export default CodeEditor;
