import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addTestCaseToAssessment,
  deleteTestCaseAssessment,
  updateTestCaseInAssessment,
} from "../reducers/AssessmentsReducer";
import _ from "lodash";

const TestCase = ({
  testCase,
  assessmentId,
  index,
  handleDeleteTestCase,
  handleUpdateTestCase,
  setAssessment,
}) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [content, setContent] = useState(testCase?.content ?? "");
  const [output, setOutput] = useState(testCase?.output ?? "");
  const [isHidden, setIsHidden] = useState(testCase?.isHidden ?? false);

  console.log(testCase, content, output, isHidden);

  const onSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("content", content);
    formData.append("output", output);
    formData.append("isHidden", isHidden);

    setIsProcessing(true);
    if (testCase.id) {
      dispatch(
        updateTestCaseInAssessment({ testCaseId: testCase.id, formData })
      )
        .then(({ payload }) => {
          setIsProcessing(false);
          handleUpdateTestCase?.(index, payload);
        })
        .finally(() => setIsProcessing(false));
    } else {
      dispatch(addTestCaseToAssessment({ assessmentId, formData }))
        .then(({ payload }) => {
          setIsProcessing(false);
          setAssessment(payload);
        })
        .finally(() => setIsProcessing(false));
    }
  };

  const onDelete = () => {
    if (testCase?.id) {
      setIsProcessing(true);
      dispatch(deleteTestCaseAssessment({ testCaseId: testCase.id }))
        .then(({ payload }) => {
          setIsProcessing(false);
          handleDeleteTestCase(index, testCase);
        })
        .finally(() => setIsProcessing(false));
    } else {
      handleDeleteTestCase(index, testCase);
    }
  };

  useEffect(() => {
    setContent(testCase?.content ?? "");
    setOutput(testCase?.output ?? "");
    setIsHidden(testCase?.isHidden ?? false);
  }, [testCase]);

  return (
    <Paper
      component={"form"}
      onSubmit={onSubmit}
      variant="outlined"
      elevation={8}
      sx={{ mt: 3 }}
    >
      <Box mx={2} pt={2} display={"flex"} justifyContent={"space-between"}>
        <FormControlLabel
          disabled={isProcessing}
          control={
            <Checkbox
              size="small"
              name="isHidden"
              checked={isHidden}
              onChange={(e, checked) => setIsHidden(checked)}
            />
          }
          label="Hidden Test Case"
        />
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </Box>
      <Box width={"100%"} display={"flex"} flexWrap={"wrap"}>
        <TextField
          size="small"
          value={content}
          disabled={isProcessing}
          sx={{ flexGrow: 1, m: 2 }}
          onChange={(e) => setContent(e.currentTarget.value)}
          multiline
          label="Input"
          variant="outlined"
        />
        <TextField
          size="small"
          sx={{ flexGrow: 1, m: 2 }}
          disabled={isProcessing}
          value={output}
          onChange={(e) => setOutput(e.currentTarget.value)}
          multiline
          label="Ouput"
          variant="outlined"
        />
      </Box>
      {!_.isEqual(
        { isHidden, content, output },
        {
          isHidden: testCase.isHidden,
          content: testCase.content,
          output: testCase.output,
        }
      ) && (
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={{ mb: 2, mr: 2 }}
            size="small"
          >
            Save
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default TestCase;
