import { TableCell, TableRow } from "@mui/material";

const ScoreBoadTableRow = ({
  user,
  lastSubmitted,
  assessmentCount,
  successfulSubmissions,
  completedProAssignmentsCount,
}) => {
  return (
    <TableRow>
      <TableCell>{user?.name}</TableCell>
      <TableCell>{user?.email}</TableCell>
      <TableCell align="center">
        {new Date(lastSubmitted).toLocaleString()}
      </TableCell>
      <TableCell align="center">
        {Object.keys(successfulSubmissions).length}/{assessmentCount}
      </TableCell>
      <TableCell align="center">{completedProAssignmentsCount}</TableCell>
    </TableRow>
  );
};

export default ScoreBoadTableRow;
